import React, {useEffect} from 'react';
import './styles.css'; // Assumindo que você tenha um arquivo CSS para estilos globais
import favicon_logo from '../../images/favicon.png';

function Home() {

    useEffect(() => {
        configPage();
    }, [])

    const configPage = () => {
        const favicon = document.querySelector('link[rel="icon"]');
        favicon.href = favicon_logo;

        // Altera o título da página
        document.title = 'Solution apoio';
    };

    return (
        <div className="App">
        <div className="maintenance-container">
            <h1>Website em Manutenção</h1>
            <p>Estamos atualmente realizando algumas melhorias no nosso site. Por favor, volte mais tarde.</p>
        </div>
        </div>
    );
}

export default Home;
